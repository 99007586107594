import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
    selector: 'app-help-content',
    templateUrl: './help-content.component.html',
    styleUrls: ['./help-content.component.scss'],
    standalone: false
})
@UntilDestroy({ checkProperties: true })
export class HelpContentComponent {
    constructor(
        public dialogRef: MatDialogRef<HelpContentComponent>,
        @Inject(MAT_DIALOG_DATA) public content: any,
        private sanitizer: DomSanitizer
    ) {}

    contentURL: SafeResourceUrl;
    @ViewChild('videoplayer') videoplayer: ElementRef;

    ngOnInit() {
        this.contentURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.path);
    }

    playVideo(): void {
        this.videoplayer.nativeElement.play();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    showIframe(): boolean {
        return this.content.type === 'html' || this.content.type === 'media';
    }

    showVideo(): boolean {
        return this.content.type === 'video';
    }
}
