import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';

import { NewsService } from '../../../services/news.service';
import { LoginService } from '../../../services/login.service';
import { DialogService } from '../../../services/dialog.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-news-entry',
    templateUrl: './news-entry.component.html',
    styleUrls: ['./news-entry.component.scss'],
    standalone: false
})
export class NewsEntryComponent implements OnInit {
    opened: boolean;

    markAsRead$: Subscription;
    action$: Subscription;

    @Input() read: boolean;
    @Input() news: any;

    constructor(
        private sanitizer: DomSanitizer,
        private newsService: NewsService,
        private loginService: LoginService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.opened = false;
        this.news.safe_description = this.sanitizer.bypassSecurityTrustHtml(this.news.description);
    }

    getNewsTitle(): string {
        return this.news.title;
    }

    getNewsAuthor(): string {
        return this.news.author_fullname;
    }

    getNewsDate(): string {
        if (this.news.updated_at) {
            return this.news.updated_at;
        } else {
            return this.news.created_at;
        }
    }

    canShowNewsDate(date) {
        const tmp = new Date(date);
        return tmp.toString() !== 'Invalid Date';
    }

    getNewsDescription(): string {
        if (this.opened) {
            return this.news.safe_description;
        } else {
            return '';
        }
    }

    toggleNews(): void {
        if (!this.opened) {
            this.markAsRead$ = this.newsService.markAsRead(this.news.id).subscribe(() => {
                this.opened = !this.opened;
            });
        } else {
            this.opened = !this.opened;
            this.newsService.emitNewsRead(this.news.id);
        }
    }

    deleteNews(): void {
        const action =
            this.news.authorid === this.loginService.getUser().id
                ? this.newsService.deleteNews(this.news.id)
                : this.newsService.hideNews(this.news.id);
        this.action$ = action.subscribe(() => {
            this.newsService.emitNewsDeleted(this.news.id);
        });
    }

    isNewsOpened(): boolean {
        return this.opened;
    }
}
