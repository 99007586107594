import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { IframeService } from '../../services/iframe.service';
import { Subscription } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 *  component qui gere la popup des notifications de chat.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-chat-popup',
    templateUrl: './chat-popup.component.html',
    styleUrls: ['./chat-popup.component.scss'],
    standalone: false
})
export class ChatPopupComponent implements OnInit {
    /**
     * @param {Input} notification la notification a ouvrir en popup.
     */
    @Input() notification: any;
    newChatReceived$: Subscription;

    constructor(
        private notificationService: NotificationService,
        private iframeService: IframeService
    ) {}
    /**
     * Le message à afficher à l'utilisateur
     */
    message: string;

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('ChatPopupComponent');
    }

    getMessage() {
        if (this.notification) {
            if (this.notification.title === 'message delete') {
                return 'Un message a été supprimé.';
            } else if (this.notification.title === 'message update') {
                return 'Un message a été modifié.';
            } else {
                return 'Vous avez reçu un nouveau message';
            }
        }
    }

    openChatDialog($event: Event) {
        $event.stopImmediatePropagation();
        window.parent.postMessage({ openChat: this.notification.body.discussionid }, '*');
        this.closePopup($event);
    }

    /**
     *  ferme la popup des notifications.
     * @returns {void}
     */
    closePopup($event: Event): void {
        $event.stopImmediatePropagation();
        this.iframeService.closeComponent('ChatPopupComponent');
    }

    ngOnInit() {
        this.newChatReceived$ = this.notificationService.newChatReceived.subscribe((data: any) => {
            this.iframeService.openComponent('ChatPopupComponent');
            this.notification = data;
            setTimeout(() => {
                this.iframeService.closeComponent('ChatPopupComponent');
            }, 8000);
        });
    }
}
