import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { IframeService } from '../../services/iframe.service';
import { DialogService } from '../../services/dialog.service';
import { NewsService } from '../../services/news.service';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { ApplicationService } from '../../services/application.service';
import { UntilDestroy } from '@ngneat/until-destroy';

/**
 *  component qui gere les popup liees aux notifications.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    standalone: false
})
export class NewsComponent implements OnInit {
    subscriptions = new Subscription();

    readNews: Array<any>;
    unreadNews: Array<any>;
    isInited = false;

    search: string;
    startDate: Date;
    endDate: Date;

    unreadNewsOpened: boolean;
    readNewsOpened: boolean;

    constructor(
        private applicationService: ApplicationService,
        private iframeService: IframeService,
        private dialogService: DialogService,
        private newsService: NewsService,
        private loginService: LoginService
    ) {}

    ngOnInit() {
        this.readNews = [];
        this.unreadNews = [];
        this.initDates();

        this.subscriptions.add(
            this.loginService.iconsLoaded.subscribe(() => {
                if (
                    this.applicationService.getCurrentApplication().app_id === 'easitraining' ||
                    this.applicationService.getCurrentApplication().app_id === 'easiconnect' ||
                    this.applicationService.getCurrentApplication().app_id === 'easilearning'
                ) {
                    this.getNews();
                    this.subscriptions.add(
                        this.newsService.newsRead.subscribe((newsId: number) => {
                            for (const i in this.unreadNews) {
                                if (this.unreadNews[i].id === newsId) {
                                    this.readNews.unshift(
                                        this.unreadNews.splice(parseInt(i, 10), 1)[0]
                                    );
                                    this.readNews = this.readNews.sort(function (a, b) {
                                        if (
                                            new Date(a.updated_at).getTime() <
                                            new Date(b.updated_at).getTime()
                                        ) {
                                            return 1;
                                        } else if (
                                            new Date(a.updated_at).getTime() >
                                            new Date(b.updated_at).getTime()
                                        ) {
                                            return -1;
                                        } else {
                                            return 0;
                                        }
                                    });
                                }
                            }
                        })
                    );

                    this.subscriptions.add(
                        this.newsService.allNewsRead.subscribe(() => {
                            this.readNews = this.unreadNews.concat(this.readNews);
                            this.unreadNews = [];
                        })
                    );

                    this.subscriptions.add(
                        this.newsService.newsDeleted.subscribe((newsId: number) => {
                            for (const i in this.unreadNews) {
                                if (this.unreadNews[i].id === newsId) {
                                    this.unreadNews.splice(parseInt(i, 10), 1);
                                }
                            }

                            for (const i in this.readNews) {
                                if (this.readNews[i].id === newsId) {
                                    this.readNews.splice(parseInt(i, 10), 1);
                                }
                            }
                        })
                    );
                }
            })
        );
    }

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('NewsComponent');
    }

    close(): void {
        this.iframeService.closeComponent('NewsComponent');
    }

    toggleUnreadNews(): void {
        this.unreadNewsOpened = !this.unreadNewsOpened;
        // this.readNewsOpened = false;
    }

    toggleReadNews(): void {
        this.readNewsOpened = !this.readNewsOpened;
        // this.unreadNewsOpened = false;
    }

    isOpenedUnreadNews(): boolean {
        return this.unreadNewsOpened;
    }

    isOpenedReadNews(): boolean {
        return this.readNewsOpened;
    }

    getNews() {
        this.subscriptions.add(
            this.newsService
                .getReadNews(this.search, this.startDate, this.endDate)
                .subscribe((news: any) => {
                    this.readNews = news;
                })
        );
        this.subscriptions.add(
            this.newsService
                .getUnreadNews(this.search, this.startDate, this.endDate)
                .subscribe((news: any) => {
                    this.unreadNews = news;
                    if (this.unreadNews.length) {
                        this.unreadNewsOpened = true;
                        this.readNewsOpened = true;
                        if (window.top === window.parent && !this.isInited) {
                            this.dialogService.openUnreadNewsDialog(Array.from(news));
                            this.isInited = true;
                        }
                        this.newsService.emitNumberNewsUnseen(this.getUnreadNewsCount());
                    } else {
                        this.unreadNewsOpened = false;
                        this.readNewsOpened = true;
                    }
                })
        );
    }

    getReadNewsCount(): number {
        return this.readNews.length;
    }

    getUnreadNewsCount(): number {
        return this.unreadNews.length;
    }

    initDates(): void {
        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setMonth(this.startDate.getMonth() - 3);
    }

    cancelSearch(): void {
        this.search = '';
        this.getNews();
    }

    cancelFilters(): void {
        this.initDates();
        this.cancelSearch();
    }

    markAllAsRead(): void {
        this.subscriptions.add(
            this.newsService.markAllAsRead().subscribe(() => {
                this.readNews = this.unreadNews.concat(this.readNews);
                this.unreadNews = [];
                this.newsService.emitAllNewsRead();
            })
        );
    }
}
