import * as i0 from '@angular/core';
import { EventEmitter, Directive, Inject, Input, Output, NgModule } from '@angular/core';
import { DOCUMENT } from '@angular/common';
class NgClickOutsideDirective {
  constructor(_el, _ngZone, document) {
    this._el = _el;
    this._ngZone = _ngZone;
    this.document = document;
    this.clickOutsideEnabled = true;
    this.attachOutsideOnClick = false;
    this.delayClickOutsideInit = false;
    this.emitOnBlur = false;
    this.exclude = '';
    this.excludeBeforeClick = false;
    this.clickOutsideEvents = '';
    this.clickOutside = new EventEmitter();
    this._nodesExcluded = [];
    this._events = ['click'];
    this._initOnClickBody = this._initOnClickBody.bind(this);
    this._onClickBody = this._onClickBody.bind(this);
    this._onWindowBlur = this._onWindowBlur.bind(this);
  }
  ngOnInit() {
    this._init();
  }
  ngOnDestroy() {
    this._removeClickOutsideListener();
    this._removeAttachOutsideOnClickListener();
    this._removeWindowBlurListener();
  }
  ngOnChanges(changes) {
    if (changes['attachOutsideOnClick'] || changes['exclude'] || changes['emitOnBlur']) {
      this._init();
    }
  }
  _init() {
    if (this.clickOutsideEvents !== '') {
      this._events = this.clickOutsideEvents.split(',').map(e => e.trim());
    }
    this._excludeCheck();
    if (this.attachOutsideOnClick) {
      this._initAttachOutsideOnClickListener();
    } else {
      this._initOnClickBody();
    }
    if (this.emitOnBlur) {
      this._initWindowBlurListener();
    }
  }
  _initOnClickBody() {
    if (this.delayClickOutsideInit) {
      setTimeout(this._initClickOutsideListener.bind(this));
    } else {
      this._initClickOutsideListener();
    }
  }
  _excludeCheck() {
    if (this.exclude) {
      try {
        const nodes = Array.from(this.document.querySelectorAll(this.exclude));
        if (nodes) {
          this._nodesExcluded = nodes;
        }
      } catch (err) {
        console.error('[ng-click-outside] Check your exclude selector syntax.', err);
      }
    }
  }
  _onClickBody(ev) {
    if (!this.clickOutsideEnabled) {
      return;
    }
    if (this.excludeBeforeClick) {
      this._excludeCheck();
    }
    if (!this._el.nativeElement.contains(ev.target) && !this._shouldExclude(ev.target)) {
      this._emit(ev);
      if (this.attachOutsideOnClick) {
        this._removeClickOutsideListener();
      }
    }
  }
  /**
   * Resolves problem with outside click on iframe
   * @see https://github.com/arkon/ng-click-outside/issues/32
   */
  _onWindowBlur(ev) {
    setTimeout(() => {
      if (!this.document.hidden) {
        this._emit(ev);
      }
    });
  }
  _emit(ev) {
    if (!this.clickOutsideEnabled) {
      return;
    }
    this._ngZone.run(() => this.clickOutside.emit(ev));
  }
  _shouldExclude(target) {
    for (let excludedNode of this._nodesExcluded) {
      if (excludedNode.contains(target)) {
        return true;
      }
    }
    return false;
  }
  _initClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this.document.addEventListener(e, this._onClickBody));
    });
  }
  _removeClickOutsideListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this.document.removeEventListener(e, this._onClickBody));
    });
  }
  _initAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.addEventListener(e, this._initOnClickBody));
    });
  }
  _removeAttachOutsideOnClickListener() {
    this._ngZone.runOutsideAngular(() => {
      this._events.forEach(e => this._el.nativeElement.removeEventListener(e, this._initOnClickBody));
    });
  }
  _initWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.addEventListener('blur', this._onWindowBlur);
    });
  }
  _removeWindowBlurListener() {
    this._ngZone.runOutsideAngular(() => {
      window.removeEventListener('blur', this._onWindowBlur);
    });
  }
}
NgClickOutsideDirective.ɵfac = function NgClickOutsideDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgClickOutsideDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(DOCUMENT));
};
NgClickOutsideDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NgClickOutsideDirective,
  selectors: [["", "clickOutside", ""]],
  inputs: {
    clickOutsideEnabled: "clickOutsideEnabled",
    attachOutsideOnClick: "attachOutsideOnClick",
    delayClickOutsideInit: "delayClickOutsideInit",
    emitOnBlur: "emitOnBlur",
    exclude: "exclude",
    excludeBeforeClick: "excludeBeforeClick",
    clickOutsideEvents: "clickOutsideEvents"
  },
  outputs: {
    clickOutside: "clickOutside"
  },
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[clickOutside]',
      standalone: true
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, {
    clickOutsideEnabled: [{
      type: Input
    }],
    attachOutsideOnClick: [{
      type: Input
    }],
    delayClickOutsideInit: [{
      type: Input
    }],
    emitOnBlur: [{
      type: Input
    }],
    exclude: [{
      type: Input
    }],
    excludeBeforeClick: [{
      type: Input
    }],
    clickOutsideEvents: [{
      type: Input
    }],
    clickOutside: [{
      type: Output
    }]
  });
})();

/**
 * @deprecated use NgClickOutsideDirective directly as it is a Standalone Component, the Module will be removed in Version 12
 */
class NgClickOutsideModule {}
NgClickOutsideModule.ɵfac = function NgClickOutsideModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgClickOutsideModule)();
};
NgClickOutsideModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgClickOutsideModule
});
NgClickOutsideModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgClickOutsideModule, [{
    type: NgModule,
    args: [{
      imports: [NgClickOutsideDirective],
      exports: [NgClickOutsideDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ng-click-outside2
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgClickOutsideDirective, NgClickOutsideModule };
