import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApplicationService } from './application.service';
import { ConfigService } from './config.service';

import { EasiHttpParams } from '../interceptors/easi-http-params';
import { log } from './decorators/log.decorator';

@Injectable({
    providedIn: 'root'
})
/**
 * Service gérant la récupération des json de configuration de l'aide.
 */
export class HelpService {
    constructor(
        private http: HttpClient,
        private applicationService: ApplicationService,
        private configService: ConfigService
    ) {}

    /**
     * récupère le json de l'aide en fonction du nom d'app fourni en paramètre.
     * @param appName le nom de l'applicatif
     */
    @log() getTrainingHelp(appName: string): any {
        appName = this.overRideAppNameIfNecessary(appName);
        const file = this.configService.getConfig().header.stripe ? 'test.json' : 'help.json';
        return this.http.get(`https://cdn.easi-training.fr/help/${appName}/${file}`, {
            params: new EasiHttpParams({
                overrideBaseUrl: true,
                noCredentials: true,
                noError: true
            })
        });
    }

    @log() getContacts(params: any): any {
        return this.http.get(`/contacts`, { params });
    }

    @log() getContent(contentId: number): any {
        return this.http.get(
            `${this.configService.getConfig().endpoint.training.backend}/contents/${contentId}`,
            {
                params: new EasiHttpParams({
                    overrideBaseUrl: true,
                    noError: true,
                    params: {
                        log: 'teacher'
                    }
                })
            }
        );
    }

    /**
     * vérifie si le reporting est ouvert, et remplace le nom de l'app le cas échéant.
     * @param appName cf. this.getTrainingHelp()
     */
    overRideAppNameIfNecessary(appName: string): string {
        if (this.applicationService.getParentURL().includes('/student')) {
            return 'easilearning';
        }
        if (this.applicationService.getParentURL().includes('/reporting')) {
            return 'easireporting';
        }
        return appName;
    }
}
