import { Pipe, PipeTransform } from '@angular/core';
import { AppNotificationsSettings } from '../structures/app-notifications-settings';
import { Application } from '../structures/application';

@Pipe({
    name: 'notificationsFilterByApp',
    pure: false,
    standalone: false
})
export class NotificationsFilterByAppPipe implements PipeTransform {
    /**
     * filtre les notifs pour ne retourner que celles liées à une applications à laquelle on a accès
     */
    transform(notifications: AppNotificationsSettings[], applications: Application[]): unknown {
        if (notifications) {
            return notifications.filter(
                (notification: AppNotificationsSettings) =>
                    notification.app_id === 'easiconnect' ||
                    applications.filter(
                        (application: Application) => application.app_id === notification.app_id
                    ).length > 0
            );
        }
        return null;
    }
}
