import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../../services/notification.service';
import { DialogService } from '../../../services/dialog.service';
import { ApplicationService } from '../../../services/application.service';
import { LoginService } from '../../../services/login.service';

import { Application } from 'client-src/app/structures/application';

/**
 *  component qui gere chaque entite de notification dans le menu des notifications.
 */
@Component({
    selector: 'app-notification-entry',
    templateUrl: './notification-entry.component.html',
    styleUrls: ['./notification-entry.component.scss'],
    standalone: false
})
export class NotificationEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {Input} notification la notification entree en input a afficher.
     * @param {EventEmitter<string>} delNotif la notification a supprimer que l'on emet.
     * @param {Input} selected la notification selectionee recue.
     */
    subscriptions = new Subscription();
    @Input() notification: any;
    @Output() delNotif: EventEmitter<string> = new EventEmitter();
    @Input('selected') set selected(selected: boolean) {
        if (selected) {
            this.notification.selected = true;
            if (!this.notification.markAsRead) {
                this.subscriptions.add(
                    this.notificationService.markAsRead(this.notification.id).subscribe((data) => {
                        this.notification.markAsRead = true;
                    })
                );
            }
        } else {
            this.notification.selected = false;
        }
    }

    applications: Array<Application>;

    constructor(
        private notificationService: NotificationService,
        private applicationService: ApplicationService,
        private dialogService: DialogService,
        private loginService: LoginService
    ) {}

    /**
     * @returns {boolean} l'etat de selection de la notification.
     */
    isSelected(): boolean {
        return this.notification.selected;
    }

    /**
     * @returns {boolean} l'etat de lecture de la notification.
     */
    isRead(): boolean {
        return this.notification.markAsRead;
    }

    /**
     * @returns {string} le body d'une notif, si elle est vide son titre qui subtituera alors le body.
     */
    getNotifBody(): string {
        if (this.notification.body) {
            return this.notification.body;
        } else {
            return this.notification.title;
        }
    }

    /**
     *  supprimer une notification via la fonction associee dans notificationService.
     * @returns {void}
     */
    deleteNotification($event): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.notificationService.deleteNotification(this.notification.id).subscribe(() => {
                this.delNotif.emit(this.notification.id);
            })
        );
    }

    /**
     *  accede a la couleur de l'application demandee en parametre.
     * @param id l'identifiant de l'application a rechercher ici.
     * @returns {string} la couleur de l'application recherchee.
     */
    getNotificationColor() {
        if (
            this.applications.some(
                (application: Application) => application.app_id === this.notification.app_id
            )
        ) {
            return this.applications.filter(
                (application: Application) => application.app_id === this.notification.app_id
            )[0].color;
        } else if (this.notification.app_id === 'easiconnect') {
            return '#035a77';
        }
    }

    /**
     *  accede a l'icone de l'application demandee en parametre.
     * @param id l'identifiant de l'application a rechercher ici.
     * @returns {string} le nom de l'icone de l'application recherchee.
     */
    getNotificationIcon() {
        if (
            this.applications.some(
                (application: Application) => application.app_id === this.notification.app_id
            )
        ) {
            return this.applications.filter(
                (application: Application) => application.app_id === this.notification.app_id
            )[0].icon_font;
        } else if (this.notification.app_id === 'easiconnect') {
            return 'icon-easi-control-line';
        }
    }

    goToNotification() {
        if (this.notification.url) {
            window.open(this.notification.url, '_blank');
        }
    }

    ngOnInit() {
        if (!isNaN(this.notification.timecreated)) {
            this.notification.timecreated *= 1000;
        }
        this.notification.date = new Date(this.notification.timecreated);

        if (this.loginService.getUser()) {
            this.applicationService
                .getApplications()
                .subscribe((applications: Array<Application>) => {
                    this.applications = applications;
                });
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
