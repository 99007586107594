<div id="iframe">
    <iframe *ngIf="showIframe()" [src]="contentURL"></iframe>
    <video
        *ngIf="showVideo()"
        controls
        [src]="contentURL"
        (click)="playVideo()"
        type="video/mp4"
        #videoplayer
    >
        <!-- <source > -->
        Navigateur non supporté.
    </video>
</div>
