import { Pipe, PipeTransform } from '@angular/core';

/**
 *  pipe qui filtre les applications en fonction du sous-domaine adequat,
 * il est utilise dans le menu des applications pour les filtrer dans les bonnes categories.
 */
@Pipe({
    name: 'appFilterPipe',
    standalone: false
})
export class AppFilterPipe implements PipeTransform {
    transform(applications: any[], section: string): any {
        const res = [];
        for (const i in applications) {
            if (applications[i].section === section) {
                res.push(applications[i]);
            }
        }
        return res;
    }
}
