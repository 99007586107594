import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { LoginService } from '../../services/login.service';
import { ConfigService } from '../../services/config.service';
import { IframeService } from '../../services/iframe.service';
import { DialogService } from '../../services/dialog.service';
import { ApplicationService } from '../../services/application.service';
import { FlashMessageService } from '../../services/flash-message.service';

import { Application } from '../../structures/application';

import { UntilDestroy } from '@ngneat/until-destroy';

/**
 *  component qui gere le menu utilisateur ainsi que les donnees relative a l'utilisateur connecte.
 */
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    standalone: false
})
export class UserComponent implements OnInit {
    roles = [];
    additionalRoles = [];
    email = '';
    fullName = '';
    username = '';
    avatar;
    structureId = 0;
    localStructure = '';
    loggedOut: boolean;
    logoutUrl: Array<any>;
    subscriptions = new Subscription();

    applications: Array<Application>;

    @Input() openedUser: any;

    constructor(
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private configService: ConfigService,
        private iframeService: IframeService,
        private dialogService: DialogService,
        private flashMessageService: FlashMessageService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        if (this.loginService.getUser()) {
            this.applicationService
                .getApplications()
                .subscribe((applications: Array<Application>) => {
                    this.applications = applications;
                });
        }
        this.setMail();
        this.setName();
        this.setAvatar();
        this.setRoles();
        this.setAditionnalRoles();
        this.setUsername();

        window.addEventListener('message', (event) => {
            if (event.data.endOfAzure) {
                this.flashMessageService.flash(
                    'Votre compte EASi a bien été lié à votre compte Microsoft'
                );
                this.dialogService.openAzureConfirmation().subscribe(() => {
                    this.loginService.getUser().hasAzureLogin = true;
                });
            }
        });
    }

    isOpened() {
        return this.iframeService.isOpenedComponent('UserComponent');
    }

    /**
     * @returns la couleur de l'application courante.
     */
    getApplicationColor(): string {
        if (this.applicationService.getCurrentApplication()) {
            return this.applicationService.getCurrentApplication().color;
        } else {
            return '#424242';
        }
    }

    /**
     *  poste au web service approprie le changement d'email.
     * @returns {void}
     */
    updateEmail(): void {
        if (this.loginService.getUser().email) {
            window.top.location.href = `${this.configService.getConnectFrontEndPoint()}/#/updateEmail`;
        } else {
            window.top.location.href = `${this.configService.getConnectFrontEndPoint()}/#/setEmail?userid=${
                this.loginService.getUser().id
            }`;
        }
    }

    /**
     *  poste au web service approprie le changement de photo.
     * @returns {void}
     */
    updatePhoto(): Promise<void> {
        return this.dialogService.openChangePhotoDialog();
    }

    /**
     *  poste au web service approprie le changement de mot de passe.
     * @returns {void}
     */
    updatePassword(): void {
        window.top.location.href =
            this.configService.getConnectFrontEndPoint() + '/#/updatePassword';
    }

    /**
     * ouvre la fenêtre de gestion de mes notifications
     */
    manageNotifications() {
        this.iframeService.isOpenedComponent('NotificationsManagerComponent')
            ? this.iframeService.closeComponent('NotificationsManagerComponent')
            : this.iframeService.openComponent('NotificationsManagerComponent');
        this.iframeService.closeComponent('UserComponent');
    }

    showTokenManager(): void {
        return this.configService.getConfig().token;
    }

    showMobileAppManager(): void {
        return this.configService.getConfig().mobile;
    }

    manageToken(): void {
        this.dialogService.openTokenManagerDialog();
    }

    manageMobileApp(): void {
        this.dialogService.openMobileAppDialog();
    }

    showAzureContainer() {
        return (
            !this.loginService.getUser().roles.nationalAdmin &&
            this.configService.getConfig().displayAzure
        );
    }

    manageAzure() {
        if (!this.loginService.getUser().hasAzureLogin) {
            window.open(
                `${this.configService.getEndPoint()}/azure/login/azure`,
                '',
                'left=100,top=100,width=600,height=320'
            );
        } else {
            this.loginService.unlinkAzure().subscribe(() => {
                this.flashMessageService.flash(
                    `Votre compte EASi n'est maintenant plus lié à votre compte Microsoft`
                );
                this.loginService.getUser().hasAzureLogin = false;
            });
        }
    }

    getAzureLabel() {
        if (!this.loginService.getUser().hasAzureLogin) {
            return 'Lier mon compte EASi à un compte Microsoft';
        } else {
            return 'Supprimer le lien avec mon compte Microsoft';
        }
    }

    /**
     *  fonction de logout de l'utilisateur.
     * @returns {void}
     */
    logout(): void {
        this.dialogService.openLogoutDialog();
        this.loggedOut = true;
    }

    /**
     *  remplit l'email de l'utilsateur.
     * @returns {void}
     */
    setMail(): void {
        if (this.loginService.getUser()) {
            this.email = this.loginService.getUser().email;
        }
    }

    showNextCloudFrame(url): boolean {
        if (url.includes('nextcloud.cloud')) {
            for (const i in this.applications) {
                if (this.applications[i].app_id === 'nextcloud') {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    /**
     *  remplit l'username de l'utilisateur.
     * @returns {void}
     */
    setUsername(): void {
        if (this.loginService.getUser()) {
            this.username = this.loginService.getUser().username;
        }
    }

    getStructureName() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().roles.nationalAdmin) {
                return 'Administration nationale';
            } else {
                return this.loginService.getUser().localStructure;
            }
        }
    }

    getStructureIcon() {
        if (this.loginService.getUser()) {
            if (this.loginService.getUser().roles.nationalAdmin) {
                return 'icon-national';
            } else {
                return 'icon-local';
            }
        }
    }

    /**
     *  remplit le ®voinom complet de l'utilisateur.
     * @returns {void}
     */
    setName(): void {
        if (this.loginService.getUser()) {
            this.fullName =
                this.loginService.getUser().lastname.toUpperCase() +
                ' ' +
                this.loginService.getUser().firstname;
        }
    }

    setAvatar(): void {
        if (this.loginService.getUser()) {
            this.avatar = this.loginService.getUser().avatar;
        }
    }

    /**
     *  remplit a partir des donnes rentrees en dur dans le front la key, le nom et l'icone de l'utilisateur.
     * @returns {void}
     */
    setRoles(): void {
        if (this.loginService.getUser()) {
            this.roles = [];
            this.loginService.getRoles().subscribe((roles: Array<any>) => {
                for (const role of roles) {
                    if (this.loginService.getUser().roles[role.shortname]) {
                        this.roles.push(role);
                    }
                }
            });
        }
    }

    setAditionnalRoles(): void {
        if (this.loginService.getUser()) {
            this.additionalRoles = [];
            this.loginService.getAditionnalRoles().subscribe((additionalRoles: Array<any>) => {
                for (const additionalRole of additionalRoles) {
                    if (this.loginService.getUser().additionalRoles[additionalRole.shortname]) {
                        this.additionalRoles.push(additionalRole);
                    }
                }
            });
        }
    }

    canManageNotifications(): boolean {
        return !this.configService.getConfig().disable_notifications_management;
    }

    canChangeMyEmail(): boolean {
        return !this.configService.getConfig().disable_email_modification;
    }
}
