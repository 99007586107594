import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

import { ApplicationService } from '../../services/application.service';
import { IframeService } from '../../services/iframe.service';
import { LoginService } from '../../services/login.service';

import { Application } from '../../structures/application';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss'],
    standalone: false
})
export class ApplicationComponent implements OnInit {
    subscriptions = new Subscription();
    window = [];
    applications: any;
    sections: Array<any>;
    activeChildren: Array<Application>;

    constructor(
        private applicationService: ApplicationService,
        private loginService: LoginService,
        private iframeService: IframeService
    ) {}

    ngOnInit() {
        this.sections = [];
        if (this.loginService.getUser()) {
            this.subscriptions.add(
                this.applicationService
                    .getApplications()
                    .subscribe((applications: Array<Application>) => {
                        this.applications = [];
                        applications.map((application: Application) => {
                            const parentApplication = this.applications.find(
                                (appli) => appli.name === application.name
                            );
                            if (!parentApplication) {
                                this.applications.push(application);
                            } else {
                                if (parentApplication.children) {
                                    parentApplication.children.push(application);
                                } else {
                                    const firstApplication = { ...parentApplication };
                                    firstApplication.children = undefined;
                                    parentApplication.children = [firstApplication, application];
                                }
                            }
                        });
                        for (const key in this.applications) {
                            if (this.applications[key].children) {
                                this.applications[key].children.sort((a, b) =>
                                    a.frontend_url.localeCompare(b.frontend_url)
                                );
                            }
                            if (
                                this.sections.indexOf(this.applications[key].section) === -1 &&
                                this.applications[key].section !== 'null' &&
                                this.applications[key].section !== null
                            ) {
                                this.sections.push(this.applications[key].section);
                            }
                        }
                    })
            );
        }
    }

    toggleActiveChildren(children, $event) {
        $event.stopImmediatePropagation();
        this.activeChildren = children;
    }

    getChildName(child) {
        return child.frontend_url.replace(/^(http|https):\/\//g, '');
    }

    isActiveApp(app) {
        if (this.applicationService.getCurrentApplication()) {
            return app.app_id === this.applicationService.getCurrentApplication().app_id;
        }
    }

    isOpened(): boolean {
        return this.iframeService.isOpenedComponent('ApplicationComponent');
    }

    /**
     *  ouvre l'application demandee dans un nouvel onglet si elle n'est pas deja ouverte.
     * @returns {void}
     */
    goToTab(event: Event, application: Application): void {
        event.stopPropagation();

        if (this.window[application.app_id] && !this.window[application.app_id].closed) {
            this.window[application.app_id].focus();
        } else {
            if (application.login_url) {
                this.window[application.app_id] = window.open(application.login_url, '_blank');
            } else if (application.frontend_url) {
                this.window[application.app_id] = window.open(application.frontend_url, '_blank');
            }
        }
    }

    /**
     *  ouvre l'application demandee dans le meme onglet.
     * @returns {void}
     */
    goToURL(application: Application): void {
        if (!application.children) {
            if (application.login_url) {
                this.window[application.app_id] = window.open(application.login_url, '_parent');
                window.top.postMessage({ nativeWrapperRedirect: application.login_url }, '*');
            } else if (application.frontend_url) {
                this.window[application.app_id] = window.open(application.frontend_url, '_parent');
                window.top.postMessage({ nativeWrapperRedirect: application.frontend_url }, '*');
            }
        }
    }
}
